import styled from "styled-components";

const App = () => (
  <Container>
    <HeaderPlaceholder />
    <Header>
      <img src="logo.png" alt="sj logo" />
    </Header>

    <img src="waving_particles.jpg" alt="waving particles" />
    <img src="water_splash.jpg" alt="water splash" />

    <Footer>
      <img src="logo.png" alt="sj logo" />
      <InfoContainer>
        <div>서울시 송파구 문정동 가든파이브 라이프 L-8068</div>
        <div>TEL: 02)396-6042</div>
        <div>E-mail: sj@sjcom.co.kr</div>
        <div>FAX: 02)396-6043</div>
      </InfoContainer>
    </Footer>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > img {
    width: 90%;
    margin-top: 20px;
  }
`;

const HeaderPlaceholder = styled.div`
  height: 80px;
`;

const Header = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;

  height: 80px;
  padding: 10px 20px;
  box-shadow: 0 -6px 10px 5px rgba(0, 0, 0, 0.5);

  width: 100%;
  height: 80px;
`;

const Footer = styled.div`
  background-color: #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 120px;
  margin-top: 40px;
  padding: 10px 40px;

  font-size: 30px;
  font-weight: 700;
`;

const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;

  gap: 5px;

  font-size: 14px;
  font-weight: 400;
`;

export default App;
